.cardStyle {
    width: calc(33.33% - 20px);
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin: 2rem 10px;
    padding: 10px;
    border-radius: 5px;
}

.cardContainerStyle {
    display: flex;
    flex-wrap: wrap;
    margin: 80px 0;
}

.imageStyle {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.prix {
    display: flex;
    flex-direction: row;
}
.prix > div{
    padding-right: 5% ;
    padding-left: 5% ;
}
.prix > div:first-child {
    padding-left: 0 ;
}
.prix > div:last-child {
    padding-right: 0 ;
}
.prix > div:not(:last-child){
    border-right: solid 1px #21222CBF;
}
.prix p, .devis p{
    margin-bottom: 0px;
    font-size:14px;
    font-family: "ITCAvantGardeStd-Bk", sans-serif;
}
.prix p:first-child, .devis p:first-child{
    font-family: "ITCAvantGardeStd", sans-serif;
}

.nom{
    padding-top: 15px;
}

.nom a {
    font-family: 'ITCAvantGardeStd', sans-serif;
    text-decoration: none !important;
    color: #21222CBF;
    font-weight: bold;
}

.description{
padding-top: 5px;
}

.titre_salles{
    margin-top: 0px;
}

.titre_salles_bureaux{
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    font-family: 'ITCAvantGardeStd-Bk';
    color: #21222C;
}

.titre_ça{
    color: #ed6a1f;
    font-weight: 100;
    font-size: 16px;
}

.titres_salles{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: -100px; */
}
.roomlist-wrapper{
    width: 85%;
    max-width: 1500px;
    margin: auto;
}

.cardStyle .image-wrap{
    overflow: hidden;
    border-radius: 7px;
}
.cardStyle img {
    transition: transform 0.3s ease-in-out;
}
.cardStyle img:hover {
    transform: scale(1.02);
}

/* Style Desktop*/
@media screen and (min-width: 1200px){

}
/* Style Mobile */
@media screen and (max-width: 1200px) {
    .dashboard-inner-wrap{
        padding: 60px 0;
    }
    .titre_salles_bureaux {
        font-size: 28px;
    }
    .roomlist-wrapper{
        width: 95%;
        margin-top: 65px
    }
    .cardStyle {
        width: 100%;
        margin-bottom: 20px;
    }
    .cardContainerStyle {
        margin: 30px 0;
    }
    .nom a {
        font-size: 18px;
    }
    .cardStyle .description{
        font-size: 14px;
    }
    .prix > div{
        padding-right: 20px ;
        padding-left: 20px ;
    }
}
