.handle_hour_day_month {
display:flex
}


.menu-item-style {
  border: none;
  outline: none;
  background-color: transparent;
  /* Autres styles pour les options */
}

/* Styles pour la couleur du texte du sélecteur */
.handle_hour_day_month .custom-select-style,
.handle_hour_day_month option {
  color: #ed6a1f;
  background-color: transparent;
  font-weight: bold;
}
.handle_hour_day_month .custom-select-style > div{
  padding-top: 0;
  padding-left: 5px;
}

.css-1enfw87-MuiButtonBase-root-MuiButton-root{
  padding: 0;
  min-width: 0;
  color: #212B36;
}
.time-choice


@media (max-width: 1200px) {
.handle_hour_day_month{
  justify-content:center
}
}