/* style header */
.navbar-logo, .navbar-logo > div {
    padding: 0;
}

header img {
    width: 120px;
}

header .navbar-wrapper {
    justify-content: space-between;
    max-width: 1800px;
}

header .MuiToolbar-root.MuiToolbar-gutters .MuiBox-root:nth-child(2) {
    display: flex;
    flex-direction: column;
}

header .MuiList-root {
    display: flex;
    justify-content: space-between;
}

header .MuiList-root a {
    font-size: 18px;
    font-weight: 100;
}

header .MuiList-root > :last-child {
    border: 1px white solid;
}

.MuiList-root a {
    padding-left: 20px;
    padding-right: 20px;
}

header .MuiListItemText-root {
    color: white;
}

header.navbar {
    background-color: #21222C;
    flex-direction: row;
    justify-content: space-between;
}

header {
    height: 8vh;
    align-content: center;
}

/* Style Desktop */
@media screen and (min-width: 1201px) {
    header.navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2%;
        height: 5rem;
    }
}

/* Style responsive */
@media screen and (max-width: 1200px) {
    header.navbar {
        padding: 0;
        height: 80px;
    }

    header .navbar-wrapper {
        width: 100%;
        justify-content: center;
        padding: 1rem 0;
    }

    .navbar-burger {
        position: absolute;
        right: 2rem;
    }

    .sidebar-menu .menu-wrapper {
        margin: 2rem 0;
    }

    header img {
        width: 100px;
    }
}
