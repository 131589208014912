.css-p6g64r-DayCell{
      text-decoration: line-through;
    background-color: #f1f1f1;
}
.css-p6g64r-DayCell .css-1awxhgd-DayCellContent{
    color: black !important;
 }

/* Media queries pour le responsive */
@media (max-width: 1200px) {
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-1ghyqds-MuiPaper-root{
  width: 82vw;
}
}
