body {
    background-color: #fff;
}

.account-wrapper {
    display: flex;
    flex-direction: row;
}

* {
    margin: 0px;
    padding: 0px;
}

.anil {
    color: #fff;
}

.css-12m0xez {
    padding-top: unset;
    max-width: 50%;
}

.css-67nehf {
    position: relative;
    max-width: 100%;
}

.css-zow5z4-MuiGrid-root {
    flex-direction: column;
}

.css-10voxkt-MuiGrid-root {
    max-width: 100%;
}

.css-m6djc-MuiPaper-root-MuiCard-root {
    display: flex;
}

.css-vdljo7 {
    padding: 180px 9%;
}


@media (max-width: 1200px) {
    .account-wrapper {
        flex-direction: column;
    }

    .outlet-account {
        padding: 4vh 8vw;
    }

    .infos-factures {
        flex-direction: row;
        justify-content: space-around;
    }

    .sidebar {
        padding: 1vh 3%;
    }
}

.account-title {
    font-weight: 700;
}