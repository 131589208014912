.retrait, .ajout {
    color: #21222C;
    font-weight: bold;
    font-family: "ITCAvantGardeStd", sans-serif;
}
.retrait:hover, .ajout:hover {
    background-color:rgb(153 161 172 / 8%);
}
.compteur-label{
    margin-right: 10px;
}
.ajout{
    margin-right: 10px;
}
.nb-postes{
    font-size: 16px;
    font-family: "ITCAvantGardeStd", sans-serif;
    font-weight: bold;
    color: #21222C;
    margin: 0 10px;
}