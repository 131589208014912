
.add-equipements-container {
  margin: 2rem 0;
}

.add-equipements-button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
}

.equipements-list {
  margin-top: 10px;
}

.equipement-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.add-equipements-container label {
  width: 100%;
  cursor: pointer;
}
.equipements-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  max-width: 95%;
}
.equipements-list .MuiFormControlLabel-label{
  width: 100%;
  cursor: pointer;
}
.equipements-list .options-value{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}
.equipements-list .options-value .prix-equipement{
  flex-grow: 1;
  text-align: right;
  display: flex;
}
.equipements-list .options-value .prix-equipement::before{
  content:'';
  background: repeating-linear-gradient(to right, currentColor, currentColor 1px, transparent 2px, transparent 4px);
  height: 0.1rem;
  flex-grow: 1;
  display: inline-block;
  margin: auto 10px;
}

.equipement-item{
    display: flex;
    justify-content: space-between;
}

.ReactModal__Content{
  border: unset !important;
  background: unset !important;
  height: fit-content;
  padding: 0 !important;
  overflow: unset !important;
}

@media screen and (max-width: 768px) {
  .add-equipements-container .prix-equipement {
    width: 0;
  }
}

@media screen and (max-width: 1200px){
  .equipements-list{
    display: flex;
    flex-direction: column;
  }
}