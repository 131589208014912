@import url(https://fonts.googleapis.com/css?family=Raleway);
body, small{
    font-family: 'euclid flex', sans-serif;
    color: #213B49;
}
.hidden{
    display: none;
}
.css-1equabv-MuiGrid-root{

}
.img-container{
    width: 100%;
    height: 100%;
}
.img-container .title{
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 10px;
}
.content {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}
.content:hover .content-overlay{
    opacity: 1;
}
.content-image{
}
.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
.content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
}
.content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}
.content-details p{
    color: #fff;
    font-size: 0.8em;
}
.fadeIn-bottom{
    top: 80%;
}
.image-wrapper img.content-image{
    display:block;
    width: 100%;
}
.image-wrapper .content-details{
    display: flex;
    justify-content: space-around;
}

.all-images{
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;

    -webkit-column-gap: 6px;
    -moz-column-gap: 6px;
    column-gap: 6px;
    width: 80%;
    margin: 5em auto;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1200px){
    .all-images{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;

        -webkit-column-gap: 6px;
        -moz-column-gap: 6px;
        column-gap: 6px;
        width: 95%;
        margin: 5em auto;
        display: grid;
    }
    .image-wrapper .content-details {
        flex-direction: column;
    }
    .content-details button{
        margin: 1em 0;
    }
    .titredashboard {
        text-align: unset;
        margin: unset;
    }
    main{
        width: unset;
        margin: 2rem 1rem;
    }
}
