.nav-link {
  display: block;
  margin: 0px 20px;
  text-decoration: none;
  font-size: 16px;
}

.nav-link:hover {
  color: #ED6A1F;
}

.nav-links{
  display: flex;
  align-items: center;
}

.nav-link{
  font-size: 18px !important;
  font-weight: 500 !important;
}

.nav-links a:last-child {
  border: 2px solid white;
  border-radius: 8px;
  padding: 8px 16px;
}

@media (max-width: 1200px) {
  .nav-links {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    margin-top: 20px;
  }
}

.sidebar-menu .MuiPaper-root {
  background-color: #21222C;
}

/* Nouveau CSS pour le menu déroulant administrateur */
.admin-menu {
  position: relative;
}

.admin-menu-toggle {
  background-color: #ED6A1F; /* Couleur de fond du bouton */
  color: white; /* Couleur du texte */
  padding: 10px 20px; /* Espacement intérieur du bouton */
  border: none; /* Supprimer les bordures par défaut */
  cursor: pointer; /* Changer le curseur au survol */
  margin: 0px 20px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%; /* Positionner sous le bouton */
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 1000;
  padding: 10px 0;
}

.dropdown-menu .nav-link {
  padding: 10px 20px;
  text-decoration: none;
  color: black;
}

.dropdown-menu .nav-link:hover {
  background-color: #f1f1f1;
}
