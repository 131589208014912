/* css pour hero  */
:root {
    --max-width: 1650px;
}

.hero-top {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.hero-top div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero {
    background-color: #21222C;
    color: white;
    margin-top: -65px;
    margin-left: -16px;
    margin-right: -16px;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .hero div{
    min-height: 80vh;
} */

.hero .progra {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #2C2D37;
    padding: 30px;
    align-items: center;
    border-radius: 6px;
}

.hero img {
    max-width: 25vw;
    max-height: 60vh;
}

.hero-img {
    max-height: 50%;
}

.hero-text {
    width: 50%
}

.hero-text h3 {
    color: #ED6A1F;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 2px;
}

.hero-text h1 {
    color: #FFFFFF;
    font-family: "ITCAvantGardeStd", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: -0.3px;
    margin: 30px 0 50px 0;
}

.progra p {
    color: #FFFFFFA3;
    font-size: 18px;
    margin-bottom: 0;
}

.bouton-progra {
    font-family: "ITC Medium", Sans-serif;
    font-size: 14px;
    padding: 15px 30px;
}

.hero-text, .hero-img {
    align-items: center;
}

.bouton-progra {
    background-color: #2C2D37;
    border-radius: 4px;
    color: white;
    border-color: white;
}

.maxing-width {
    width: 80%;
    max-width: var(--max-width);
    margin: auto;
}

/* css pour cards */

.event-list {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-top: 120px;
    margin-bottom: 5rem;
}

.category-filter {
    max-width: 25%;
    background-color: #F8F8F8;
    padding: 32px;
    margin-right: 10px;
    border-radius: 8px;
    height: fit-content;
    position: sticky;
    top: 7rem;
    display: flex;
    flex-direction: column;
}

.titre-filtre {
    display: flex;
    flex-direction: row;
}

.titre-filtre img {
    object-fit: contain;
    margin-right: .5rem;
}

.titre-filtre h3 {
    margin: 0;
    color: var(--Primary-Dark-Grey, #21222C);
    font-size: 20px;
    font-style: normal;
    font-family: "ITCAvantGardeStd", sans-serif;
}

.events-list {
    width: 75%;
    margin-left: 10px;
}

.events-list {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}

.event-details-card {
    width: calc(33.33% - 10px); /* Pour que chaque carte occupe 1/3 de la largeur avec un espace de 10px entre les cartes */
    box-sizing: border-box; /* Pour inclure les marges dans le calcul de la largeur */
    border-radius: 8px;
    background-color: #F8F8F8;
    position: relative; /* Pour positionner le prix sur l'image */
    margin: 5px;
    padding: 4px;
}

.event-details-card img {
    border-radius: 5px;
}

/* Pour assurer que la dernière ligne est alignée correctement si le nombre total d'événements n'est pas un multiple de trois */
.event-details-card:nth-child(3n) {
    margin-right: 0; /* Supprimer la marge à droite pour la troisième carte de chaque ligne */
}


.event-image-container {
    position: relative;
}

.event-price {
    position: absolute;
    top: 1rem; /* Ajustez cette valeur selon votre préférence */
    left: 1.2rem; /* Ajustez cette valeur selon votre préférence */
    background-color: rgba(101, 45, 13, 0.15); /* Pour que le texte soit plus visible */
    padding: 6px 8px 4px 8px;
    border-radius: 1000px;
    font-size: 10px;
    color: white;
    backdrop-filter: blur(2px);
    letter-spacing: 0.5px;
    font-weight: 300;

}

.start-date {
    color: var(--Primary-Orange, #ED6A1F);
    margin-bottom: 0;

    /* Body Demi */
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "ITCAvantGardeStd", sans-serif;
}

.event-title {
    color: var(--Primary-Dark-Grey, #21222C);

    /* H3 */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 12px 0;
    font-family: "ITCAvantGardeStd", sans-serif;
}

.event-category {
    border-radius: 8px;
    border: 1px solid var(--Secondary-Grey, #585962);
    width: fit-content;
    padding: 5px;
    margin-bottom: 0;
    color: var(--Secondary-Grey, #585962);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; /* 183.333% */
}

.event-infos {
    padding: 20px 24px;
}

.category-checkbox {
    display: flex;
    flex-direction: column;
}

.MuiFormControlLabel-root {
    display: flex;
    flex-direction: row;
}

.hero-bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 4% auto;
}

.hero-bottom .hero-images {
    height: 210px;
    width: 30%;
}

.hero-bottom .hero-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#categories, #organisation, #tarifs {
    color: #21222C;
    margin-top: 20px;

    /* Body Demi */
    font-family: "ITCAvantGardeStd", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.category-checkbox {
    color: var(--Primary-Dark-Grey, #21222C);
    /* Body */
    font-family: "ITCAvantGardeStd", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; /* 137.5% */
    align-items: flex-start;
    padding-bottom: .5rem;
    opacity: 0.7;
}

.category-checkbox span {
    padding-top: 0;
    padding-bottom: 0;
}

.category-checkbox svg {
    width: .7em;
}

.free-checkbox {
    margin-bottom: 30px;
}

.category-filter-mobile {
    display: none;
}

.hero-img-mobile {
    display: none !important;
}

/* Pour les petits écrans */
@media only screen and (max-width: 1200px) {
    .maxing-width {
        width: 95%;
    }

    .hero {
        margin-left: 0;
        margin-right: 0;
        height: unset;
        min-height: 100vh;
        padding-bottom: 1rem;
    }

    #categories, #organisation, #tarifs {
        margin: 1rem 0;
    }

    .hero-top {
        flex-direction: column;
        align-items: center;
    }

    .hero-bottom {
        display: none;
    }

    .hero-img {
        display: none !important;
    }

    .hero-img-mobile {
        display: block !important;
        margin-bottom: 24px;
    }

    .hero-img-mobile, .hero-text {
        width: 100%;
        text-align: center;
        min-height: unset;
    }

    .hero-img-mobile img {
        max-width: 100%;
        height: auto;
    }

    .category-filter, .events-list {
        max-width: 100%;
    }

    .event-details-card {
        width: 100%;
        margin: 1rem 0;
    }

    .hero-text {
        margin-top: 65px;
    }

    .event-list {
        display: flex;
        flex-direction: column;
    }

    .events-list {
        margin: auto;
        width: 100%;
        padding-bottom: 5rem;
    }

    .category-filter {
        display: flex;
        justify-content: space-between;
    }

    .free-organisation {
        display: flex;
        flex-direction: column;

    }

    .hero-text h1 {
        font-size: 28px;
        text-align: left;
    }

    .hero-text p {
        text-align: left;
    }

    .category-checkbox span {
        font-size: 16px;
    }

    input[type="checkbox"] {
        width: 10px;
    }

    .category-filter strong {
        font-size: medium;

    }

    .hero-text h1 {
        margin: 10px auto 40px auto;
    }

    .event-list {
        padding-top: 40px;

    }

    .hero-top {
        width: 95%;
        margin-top: 3rem;
    }

    .category-filter {
        display: none;
    }

    .category-filter-mobile {
        display: block;
        position: sticky;
        top: 91vh;
        z-index: 10;
    }

    .titre-filtre .close {
        display: flex;
        justify-content: flex-end;
    }

    .titre-filtre .close img {
        margin: 0;
    }

    .mobile-modal-wrapper {
        display: flex;
    }

    .modal-filter {
        background-color: #FFF;
        width: 95%;
        border-radius: 8px;
        margin: auto;
        padding: 2rem;
        max-height: 90vh;
    }

    .filter-choices {
        max-height: 60vh;
        overflow-y: auto;
    }

    .titre-filtre {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .left-titre-filtre {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .left-titre-filtre img {
        margin-right: .5rem;
        padding-bottom: 4px;
    }

    .modal-filter .validate-btn {
        background-color: #ED6A1F;
        color: white;
        border-radius: 4px;
        padding: 10px 20px;
        margin-top: 20px;
        width: 100%;
    }

    .mobile-filter-button {
        display: flex;
        align-items: center !important;
        position: sticky;
        top: 30px;
        background-color: #ED6A1F;
        border-radius: 8px;
        padding: 10px;
        color: white;
        width: 100%;
        vertical-align: middle;
    }

    .mobile-filter-button img {
        width: 22px;
        margin-right: .5rem;
        vertical-align: middle;
        padding-bottom: 4px;
    }

    .mobile-filter-button p {
        border: none;
        color: white;
        font-size: 16px;
        font-weight: bold;
        font-family: "ITCAvantGardeStd", sans-serif;
        padding: 0;
        margin: 0;
        vertical-align: middle;
    }
}

.MuiCheckbox-root:hover {
    background-color: rgba(237, 106, 31, 0.1); /* Couleur de fond avec opacité */
}


.soutien img{
    max-width: 10%;
    margin:20px
}

.soutien-img{
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.css-194tkvh{
    width: 100%;
}