
.dashed_horizontal_separator {
    width: 100%; /* Pour que le séparateur occupe toute la largeur disponible */
    height: 1px; /* Ajustez cette valeur selon vos besoins */
    border: 1px dashed #ccc; /* Bordure pointillée et couleur du séparateur */
    margin: 10px 0 !important; /* Ajustez les marges selon vos besoins */
    position: relative;

}

.dashed_horizontal_separator::before,
.dashed_horizontal_separator::after {
    content: '';
    position: absolute;
    top: calc(50% - 12.5px);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
}

.dashed_horizontal_separator::before {
    left: calc(-12.5px - 10%);
}

.dashed_horizontal_separator::after {
    right: calc(-12.5px - 10%);
}

@media screen and (max-width: 1200px) {
    .dashed_horizontal_separator::before {
        left: -34px;
    }

    .dashed_horizontal_separator::after {
        right: -34px;
    }

    .reservation-summary-container .dashed_horizontal_separator::before {
        left: -35px;
    }

    .reservation-summary-container .dashed_horizontal_separator::after {
        right: -35px;
    }
}