.facture{
    display:flex;
    justify-content: space-between;
}


.horizontal_separator_factures{
    margin: 20px 0;
}

.historique-factures{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}