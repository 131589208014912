.photosManagerContainer {
    padding: 20px;
}

.dropzone {
    border: 2px dashed #007BFF;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: column; /* Colonne pour garder le texte explicatif en haut */
    gap: 15px;
    align-items: center;
}

.photosContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    width: 100%;
}

.dropzone p {
    margin: 0;
    font-size: 16px;
    color: #555;
    text-align: center;
    width: 100%;
}

.photoItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px; /* Taille fixe */
    height: 150px; /* Taille fixe */
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden; /* Pour masquer l'effet de suppression */
    cursor: pointer;
}

.photoThumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
}

.photoItem:hover .photoThumbnail {
    filter: brightness(70%); /* Assombrit l'image au survol */
}

.deleteButton {
    display: none; /* Caché par défaut */
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #DC3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.photoItem:hover .deleteButton {
    display: block; /* Affiche le bouton au survol */
}

.deleteButton:hover {
    background-color: #a72833;
}
