.contact-form{

}
.contact-form > div{
    margin: 0;
    width: 100%;
}
.contact-form .MuiFormControl-root{
    margin: 0.4rem;
}
.contact-form .send-form{
    width: 100%;
    background-color: #ED6A1F;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 14px 24px;
    margin-top: 1rem;
    font-size: 16px;
    letter-spacing: 1px;
}
.MuiFormLabel-asterisk{
    display: none;
}