@font-face {
  font-family: 'ITCAvantGardeStd-Bk';
  src: url('./fonts/ITCAvantGardeStd-Bk.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd';
  src: url('./fonts/ITCAvantGardeStd-BoldObl.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Cn';
  src: url('./fonts/ITCAvantGardeStd-MdCnObl.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-BkCn';
  src: url('./fonts/ITCAvantGardeStd-BkCn.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd';
  src: url('./fonts/ITCAvantGardeStd-Demi.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd';
  src: url('./fonts/ITCAvantGardeStd-MdObl.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Bk';
  src: url('./fonts/ITCAvantGardeStd-BkCnObl.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Cn';
  src: url('./fonts/ITCAvantGardeStd-DemiCn.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd-XLt';
  src: url('./fonts/ITCAvantGardeStd-XLt.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Bk';
  src: url('./fonts/ITCAvantGardeStd-BkObl.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Cn';
  src: url('./fonts/ITCAvantGardeStd-DemiCnObl.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-XLtCn';
  src: url('./fonts/ITCAvantGardeStd-XLtCn.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Bold';
  src: url('./fonts/ITCAvantGardeStd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd';
  src: url('./fonts/ITCAvantGardeStd-DemiObl.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-XLtCn';
  src: url('./fonts/ITCAvantGardeStd-XLtCnObl.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Cn';
  src: url('./fonts/ITCAvantGardeStd-BoldCn.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd';
  src: url('./fonts/ITCAvantGardeStd-Md.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardeStd-XLt';
  src: url('./fonts/ITCAvantGardeStd-XLtObl.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Cn';
  src: url('./fonts/ITCAvantGardeStd-BoldCnObl.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'ITCAvantGardeStd-Cn';
  src: url('./fonts/ITCAvantGardeStd-MdCn.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Utilisez la police dans votre application */
body {
  font-family: "ITCAvantGardeStd-Bk", sans-serif;

}
*{
  font-family: "ITCAvantGardeStd-Bk", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  font-family: ITCAvantGardeStd-Bk;
  color: #21222CBF;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.css-mpr9kc-MuiPaper-root-MuiDrawer-paper{
  z-index: -1;
}

.dashboard-wrap{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

/* App.css */
.app_calendar_day {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.day-column {
  border: 1px solid #ccc;
  padding: 10px;
}

.time-slot {
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
}

.selected {
  background-color: lightblue;
}

.css-vdljo7{
  background-color: white !important;
}


/*responsive*/

@media (max-width: 1200px) {
.calendar-month-container{
    flex-direction: column;
}

.pour-responsive{
text-align: center;
}

footer {
  padding: 50px 10%;
}

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-center {
    flex-direction: column;
    align-items: center;
    text-align: center; /* Ajout de la propriété pour centrer le texte */
  }

  .footer-center .menu {
    margin-bottom: 20px;
  }
  
  .footer .menu h3{
    margin-left: unset;
  }
  
  .footer-right {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-right img {
    margin: 0 10px;
  }
  
  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }
  
  .copyright{
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  #root > div > footer > div.footer-container > div.footer-center.footer-item ul{
    padding-left: unset;
  }
  
  #root > div > footer > div.footer-container > div.footer-center.footer-item h3{
    margin-left: unset;
  }

  footer .title{
    font-size: 24px;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  /* .css-c7sksf{
    padding: 10% 5% 0% 5%;
  } */
  
    .reservation-form, .proceder-paiement {
    width: 40%;
  }

  .infos_salle_right_reservation {
    width: 35%;
  }

  .compris-location{
    display: flex;
    flex-direction: column;
  }

  .compris-location td{
    margin-top: -15px;
  }

  div.infos_salle > div.infos_salle_left > div.informations > table > tbody > tr > td:nth-child(1){
    margin-top: 15px;
  }

  div.infos_salle > div.infos_salle_left > div.left-panel > div > div > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8.calendar-content.css-vok43g-MuiGrid-root{
    padding-left: 0;
  }
  
  div.infos_salle > div.infos_salle_right > div:nth-child(6){
    left: 50%;
  }

  #root > div > div > div > div > div.infos_salle > div.infos_salle_right > div:nth-child(6) {
    display: flex;
    justify-content: center;
}

/* .handle_hour_day_month{
  margin-top: 20px;
  margin-left: 50px;
} */



.reserver{
  width: 50%;
  margin-bottom: 20px;
}

/* .css-ujtxvs{
  position: absolute;
}

#root > div > header > div > button{
  right: 0;
} */
}

@media (min-width: 1201px) {
  .reservation-form, .proceder-paiement {
    width: 30%;
  }

  .infos_salle_right_reservation {
    width: 30%;
  }
}


#root > div > header{
  position: sticky;
}

/* css en production */

.css-a2m0pv-Et, .css-1aohul0{
    background-color: #ed6a1f !important;
    border-color: #ed6a1f !important;
}

.css-xommnk, .css-img1gn-Et{
    background-color: #ed6a1f !important;
}

.css-1ieyfsi, .css-69pf0s, .css-pgnb60-Et, .css-19piv90-Et{
    background-color: #ed6a1f !important;
}

.css-1grf7vp-DayCellHeader, .css-1uhpw3s-DayCellContent, .css-8c38f4, .css-1n54qpk{
    color: #ed6a1f !important;
}

.css-8lr9dq, .css-p6g64r-DayCell{
  background-color: #f1f1f1 !important;
}

.css-qkxwav-MonthContent span, .css-11n4mwx span{
  color: #ed6a1f !important;
}

.css-19ryhug-Et path, .css-h80u5e-Et path, .css-1r67th8 path, .css-16cw84e path{
  fill: #ed6a1f !important;
}

.heures{
  color: #ed6a1f;
  border-color: #ed6a1f;
}

.heures:hover{
  background-color: #ffc10724;
  border-color: #ed6a1f;
}

.MuiButton-contained{
  background-color: #ed6a1f;
  color: white;
  box-shadow: unset;
}

.MuiButton-contained:hover{
  background-color: #ed6a1f;
  color: white;
}