.wrap-notfound{
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.wrap-notfound a{
    text-decoration: none;
    color: unset;
}
.wrap-notfound .redirect{
    margin: 2rem 0;
    background-color: #ED6A1F ;
    color: white;
    padding: 16px;
    border-radius: 6px;
}