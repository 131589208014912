/* MyContext.css */

.calendar-month-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.calendar-month-container .calendar-select {
  display: flex;
  flex-direction: column;
}

.calendar-month-container label {
  font-weight: bold;
}

.calendar-month-container select {
  padding: 20px 0px;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  font-size: 14px;
}
