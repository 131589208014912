:root {
    --animationLength: 1.5s;
    --animationRadius: 12px;
    --dotSize: 8px;
}

.sampleContainer {
    height: 80vh;
    display: flex;
    justify-content: center;
    width: 100%;
}

.loader {
    position: relative;
    width: calc(var(--animationRadius) * 3 + var(--dotSize));
    height: var(--dotSize);
    margin: var(--animationRadius) auto;
}

.dot {
    display: inline-block;
    width: var(--dotSize);
    height: var(--dotSize);
    border-radius: calc(var(--dotSize) * 0.5);
    background: #ccc;
    position: absolute;
}

.dot_1 {
    animation: animateDot1 var(--animationLength) linear infinite;
    left: var(--animationRadius);
    background: #ED6A1F;
}

.dot_2 {
    animation: animateDot2 var(--animationLength) linear infinite;
    animation-delay: calc(var(--animationLength) / 3);
    left: calc(var(--animationRadius) * 2);
}

.dot_3 {
    animation: animateDot3 var(--animationLength) linear infinite;
    left: var(--animationRadius);
}

.dot_4 {
    animation: animateDot4 var(--animationLength) linear infinite;
    animation-delay: calc(var(--animationLength) / 3);
    left: calc(var(--animationRadius) * 2);
}

@keyframes animateDot1 {
    0% {
        transform: rotate(0deg) translateX(calc(-1 * var(--animationRadius)));
    }
    25% {
        transform: rotate(180deg) translateX(calc(-1 * var(--animationRadius)));
    }
    75% {
        transform: rotate(180deg) translateX(calc(-1 * var(--animationRadius)));
    }
    100% {
        transform: rotate(360deg) translateX(calc(-1 * var(--animationRadius)));
    }
}

@keyframes animateDot2 {
    0% {
        transform: rotate(-0deg) translateX(calc(-1 * var(--animationRadius)));
    }
    25% {
        transform: rotate(-180deg) translateX(calc(-1 * var(--animationRadius)));
    }
    75% {
        transform: rotate(-180deg) translateX(calc(-1 * var(--animationRadius)));
    }
    100% {
        transform: rotate(-360deg) translateX(calc(-1 * var(--animationRadius)));
    }
}

@keyframes animateDot3 {
    0% {
        transform: rotate(0deg) translateX(var(--animationRadius));
    }
    25% {
        transform: rotate(180deg) translateX(var(--animationRadius));
    }
    75% {
        transform: rotate(180deg) translateX(var(--animationRadius));
    }
    100% {
        transform: rotate(360deg) translateX(var(--animationRadius));
    }
}

@keyframes animateDot4 {
    0% {
        transform: rotate(-0deg) translateX(var(--animationRadius));
    }
    25% {
        transform: rotate(-180deg) translateX(var(--animationRadius));
    }
    75% {
        transform: rotate(-180deg) translateX(var(--animationRadius));
    }
    100% {
        transform: rotate(-360deg) translateX(var(--animationRadius));
    }
}
