.sidebar{
    position: sticky;
    background-color: #0000000D;
    padding: 3vh 3%;
  }
  
  .main-sidebar{
    display: flex;
  }
  
  .outlet-account{
    margin: 10rem 3rem;
    width:100%;
  }
  
  .menu-account{
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

/* sidebarAccount.css */
.menu-account a {
  text-decoration: none;
  color: #21222c;
  transition: color 0.3s, background-color 0.3s; /* Ajoutez une transition pour une animation en douceur */
  padding: 5px 10px;
  border-radius: 5px;  
}

.logout:hover {
  color: black !important;
}

.infos-factures a:hover {
  color: white;
  background-color: #ed6a1f;
}

.infos-factures{
  display: flex;
  flex-direction: column;
}

.logout{
  color: #ed6a1f !important;
  text-decoration: underline !important;
}

.logout-responsive{
  display: none;

}

/* Ajoutez une règle pour le responsive */
@media screen and (max-width: 1200px) {
.logout-desktop{
    display: none;
  }

  .logout-responsive{
    display: block;
  }
}


