
/*style footer */

footer{
    color: white;
    background-color: #21222C;
    bottom: 0;
    padding: 50px 19%;
    z-index: 10;
}

.footer-container{
    display:flex;
    justify-content: space-between;
}

.footer-center{
    display:flex;
}

footer .menu ul{
    display: flex;
    flex-direction: column;
}

footer .menu ul a{
    font-size: 14px;
}

footer .menu ul a:hover{
    background: none;
    color: blue;
}

footer .menu{
    display: flex;
    flex-direction: column;
}

footer .title{
    font-size: 32px;
}

footer .menu h3{
    font-size: 21px;
    margin-left: 25px;
    color: #ED6A1F;
}

.footer-bottom{
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    margin-top: 25px;
    border-top: 0.1px solid white;
}

.footer-bottom a{
    text-decoration: none;
}

.footer-bottom a:hover {
    color: blue;
}

.footer-right{
    display: flex;
    justify-content: flex-end;
}

.footer-right img{
    width: 64px;
    height: 64px;
}

footer .active{
    background: none;
}


.footer-item {
  flex-basis: 25%;
}

.copyright{
    color: #ffffffa3;
}
menu ul{
    padding-left: 0;
}
.menu li{
    list-style: none;
}
.menu li a, footer a{
    color: unset;
    text-decoration: none;
}
