

.calendar-wrapper {
  display: flex;
  width: 100%;
}

.calendar-content {
  height: 100%;
}

.time-slots {
  height: 100%;
  max-width: 100% !important;
}

.calendar-hour-container

/* Ajoutez ces styles à la fin de votre fichier CSS */

/* Media queries pour le responsive */
@media (max-width: 1200px) {
  .calendar-wrapper {
    flex-direction: column; /* Affichez les éléments en colonne sur les petits écrans */
  }

  .calendar-content, .time-slots {
    width: 100%;
    max-width: unset;
    margin: 1rem 0;
  }

  .time-slots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Permettez aux plages horaires de passer à la ligne si nécessaire */
    padding-left: 0 !important;
  }


  .time-slots Button {
    flex: 0 0 48%; /* Ajustez la largeur des boutons en fonction de vos préférences */
    margin: 0.5% 1%; /* Ajoutez un espacement entre les boutons */
  }
  
  .time-slots .MuiPaper-root{
    width: 100%;
  }

  .calendar-hour-container{
    padding: 0 !important;
  }

  .calendar-wrapper{
    margin-left: 0 !important;
  }
}
