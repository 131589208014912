
.sous-titres, .sous-titres span {
    font-family: "ITCAvantGardeStd", sans-serif;
    font-weight: bold;
}

.images_grid {
    display: flex;
}

.menu-item-style::after {
    border-bottom: 2px solid #ed6a1f;
}

.errorMessage {
    margin-bottom: 2rem;
}

.image_principale {
    flex: 1;
    height: 50%;
}

.images_secondaires {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Cela définit un espace de 10px entre les éléments */
}

.image_secondaire {
    text-align: center; /* Centre les images horizontalement dans les conteneurs */
}

.images_secondaires img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.infos_salle_right .horizontal_separator {
    width: 100%; /* Pour que le séparateur occupe toute la largeur disponible */
    height: 1px; /* Ajustez cette valeur selon vos besoins */
    background-color: #585962; /* Couleur du séparateur horizontal */
    margin: 10px 0 !important; /* Ajustez les marges selon vos besoins */
}

.infos_salle_right {
    background-color: #f1f1f1;
    width: 40%;
    padding: 3%;
    border-radius: 8px;
    height: fit-content;
    position: sticky;
    top: 160px;
    align-self: flex-start;
    margin: 0;
}

.infos_salle_left {
    width: 55%;
    margin-right: 2rem;
}

.infos_salle {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.infos_salle .infos_salle_left .prix div p:first-child {
    color: #21222C;
}

.date_horaire {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
}

.salle_details p, .salle_details ul {
    font-size: 16px;
}

.salle_details .recap, .salle_details .total {
    display: flex;
    justify-content: space-between;
}

.salle_details .reserver {
    width: 100%;
    background-color: #ED6A1F;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 14px 24px;
    margin-top: 1rem;
    font-size: 16px;
    letter-spacing: 1px;
}

.salle_details {
    max-width: 1400px;
    margin: auto;
}

.poincon {
    position: relative;
    background-color: #ED6A1F;
    width: 36px;
    height: 36px;
}


.css-m6djc-MuiPaper-root-MuiCard-root {
    border-radius: 8px !important;
}

.left-panel {
    width: 95%;
}

.poste {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.handleandincrement {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.handleandincrement p {
    margin-bottom: 0;
}

.salle_details .titre {
    color: #000;
    font-family: "ITCAvantGardeStd";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dashboard-inner-wrap {
    padding-top: 65px;
    overflow: visible !important;

}

.images-wrapper {
    margin-top: 20px;
}

.infos_salle .infos_salle_left .prix > div {
    text-align: center;
    padding-right: 11%;
    padding-left: 11%;
}

.infos_salle .prix {
    justify-content: center;
    width: 95%;
}

.salle_details .informations ul {
    column-count: 3;
}

.image-card {
    overflow: hidden;
    border-radius: 7px;
}

.image-card img {
    transition: transform 0.3s ease-in-out;
}

.image-card img:hover {
    transform: scale(1.02);
}

.duration-wapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile {
    display: none;
}


.reserved-dates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

/*Version Mobile et tablette*/
@media screen and (max-width: 1200px) {
    .infos_salle, .infos_salle_bottom {
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
        width: 91%;
    }

    .infos_salle .calendar-hour-container {
        padding: 0;
    }

    .handleandincrement {
        width: 100%;
    }

    .infos_salle_left,
    .infos_salle_right {
        width: 100%;
        margin-right: 0;
    }

    .images_grid {
        flex-direction: column;
    }

    .image_principale {
        height: auto;
        max-width: 100%;
    }

    .images_secondaires {
        grid-template-columns: 1fr;
    }

    .infos_salle_right {
        width: 100%;
        margin-top: 20px;
        padding: 1.5rem;
        text-align: left;
    }

    .infos_salle .calendar-wrapper {
        margin: 0;
    }

    .salle_details .reserver {
        margin-top: 10px;
    }

    .infos_salle_right .horizontal_separator {
        margin: 10px 0 !important;
    }

    .left-panel {
        width: 100%;
    }

    .calendar-container {
        margin-bottom: 2rem;
    }

    .calendar-container > div {
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0px 3px 3px -2px rgba(145, 158, 171, 0.2), 0px 3px 4px 0px rgba(145, 158, 171, 0.14), 0px 1px 8px 0px rgba(145, 158, 171, 0.12);
    }

    .infos_salle .time-slots, .infos_salle .calendar-content {
        max-width: unset;
        flex-basis: unset;
        width: 100%;
        padding: 1.5rem 0 !important;
    }

    .infos_salle .calendar-content > div {
        width: 100%;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .mobile .titre {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .dashboard-inner-wrap {
        padding-top: 0px;
    }

}

.salle_details .informations ul {
    column-count: unset;
}

.infos_salle .infos_salle_left .prix {
    width: 100%;
}

.infos_salle .infos_salle_left .prix > div {
    padding-right: 5%;
    padding-left: 5%;
}

.carousel img {
    object-fit: cover;
    position: absolute;
    bottom: -50%;
    width: 100%;
}

.handleandincrement {
    flex-direction: column;
}

.duration-wapper {
    justify-content: left;
    margin-bottom: 1rem;
}

.time-choice .custom-select-style {
    color: red;
}

.salle_details_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.salle_details_top button{
    width: auto !important;
    font-size: 12px !important;
}