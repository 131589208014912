.carouselContainer {
    width: 80%; /* Ajuste la largeur pour centrer le carousel sur la page */
    margin: 100px auto; /* Centrer le carousel horizontalement */
}

.carouselContainer h2 {
    text-align: center;
    margin-bottom: 50px;
    color: #333;
}   

.description-espaces-communs {
    text-align: center;
    margin-bottom: 50px;
    color: #333;
}   

.carouselItem {
    position: relative;
    text-align: center;
    padding: 10px;
    box-sizing: border-box; /* Assurer que le padding est pris en compte dans la largeur */
}

.carouselImage {
    width: 100%;
    height: 250px; /* Fixe une hauteur pour toutes les images */
    object-fit: cover; /* L'image remplira l'espace sans être déformée */
    border-radius: 5px;
}

.photoTitle {
    font-weight: bold;
    margin-top: 10px;
    color: #333;
}

/* Personnalisation des points du carousel */
.slick-dots li button:before {
    color: #007BFF;
}

.slick-prev, .slick-next {
    z-index: 1;
    color: #007BFF;
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.8); /* Fond noir transparent */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center; /* Centre verticalement */
    justify-content: center; /* Centre horizontalement */
    z-index: 1000; /* Assure que l'overlay est au-dessus de tout */
}

.modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    height: 100% !important;
    overflow: auto;
    z-index: 1001; /* Assure que la modal est au-dessus de l'overlay */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Ajoute une ombre pour l'esthétique */
}


.modalImage {
    max-width: 100%;
    max-height: 70vh; /* Limite la hauteur à 70% de la hauteur de la fenêtre */
    object-fit: contain; /* Garde l'aspect ratio de l'image */
    border-radius: 10px;
}

.closeButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeButton:hover {
    background-color: #0056b3;
}
