.total{
  display: flex;
  justify-content: space-between;
}
.salle-summary{
  font-size: 20px;
  font-weight: 800;
}
.icon-circle{
  margin-right: 10px;
}
.reservee, .payee{
  display: flex;
}
.checked{
  display: flex;
  justify-content: space-around;
  font-size: 22px;
  font-weight: 800;
}
.clef{
  max-width: 80%;
  margin: auto;
}
.tout-bon{
  margin-top: 50px;
  text-align: center;
}
.cgv-lien{
  color: unset;
}
.cgv{
  font-size: 10px;
}
.finaliser{
  margin-bottom: -20px;
}

.proceder{
  font-weight: 800;
  margin-top: 50px;
}

.se-connecter, .creer-compte, .tout-bon{
  color: #21222c;
  font-size: 50px;
  font-weight: 800;
}
.title-login, .title-register{
  display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.bienvenue, .ravis{
  color: #ed6a1f;
  font-weight: 100;
  letter-spacing: 3px;
}

.recap{
  display: flex;
  justify-content: space-between;
  /* margin-right: 20px; */

}

.reservation-summary-container{
  display: flex;
  justify-content: space-around;
  max-width: 1400px;
  margin: auto;
}

.reservation-form {
  padding: 20px;
  border-right: 1px solid #ccc;
}


.image_principale{
  border-radius: 8px;
  width:40vw;
  height: 250px;
  margin: auto;
  object-fit: cover;
}

.send-evoliz{
  background-color: #ed6a1f;
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 6px;
  margin: 100px 0px;
}

.reservation-form, .proceder-paiement{
  width: 40%;
  margin: 5%;
}
/* 
.proceder-paiement{
  width: 40%;
} */

.infos_salle_right_reservation{
  width: 35%;
}

.reservation-form, .proceder-paiement, .infos_salle_right_reservation {
  width: 100%;
  margin: 2%;
}

.image_principale {
  height: 300px;
  margin-bottom: 50px;
}

.send-evoliz {
  margin: 20px 0;
  height: 46px;
  
}
@media (max-width: 1200px) {
  .reservation-summary-container {
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  .image_principale{
    width: 100%;
  }
  .reservation-form, .proceder-paiement, .infos_salle_right_reservation {
    width: 90%;
    margin: 2%;
  }

  .reservation-summary-container .infos_salle_right_reservation {
    position: relative;
    top:0;
    margin-bottom: 2rem;
  }
}